import axios from "../axios";

const base = "/account";

export const loginUser = (payload) => {
  return axios.post(`${base}/login/`, payload);
};

export const updateUser = (payload) => {
  return axios.patch(`${base}/profile/`, payload);
};

export const getProfile = () => {
  return axios.get(`${base}/profile/`);
};

export const getSessions = (filter = "") => {
  return axios.get(`/session/${filter}`);
};
export const getSession = (id) => {
  return axios.get(`/session/${id}/`);
};
export const createSession = (payload) => {
  return axios.post(`/session/save-new/`, payload);
};
