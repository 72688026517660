import { useEffect, useState, useRef, lazy, Suspense } from "react";
import {
  Routes,
  Route,
  Link as RouterLink,
  Navigate,
  useNavigate,
} from "react-router-dom";

import "./App.css";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  useMediaQuery,
} from "@mui/material";
import UserDropDown from "./components/layout/UserDropDown";
import isAuthenticated, { getUser, setUser as setUserData } from "./utils/auth";
import { getProfile } from "./api/lib/user";
import { useSnackbar } from "notistack";
import { setToken } from "./api/axios";
import routeNames from "./router/routeNames";
import { breakpoints } from "./styles/theme";
import routes from "./router/routes";

const App = () => {
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const promptHandler = useRef(null);

  const handleProfile = () => {
    getProfile()
      .then((response) => {
        const temp = getUser();
        temp.user = response.data;
        setUserData(temp);
        setUser(temp);
      })
      .catch(() => {
        enqueueSnackbar("Connection error", { variant: "error" });
      });
  };

  useEffect(() => {
    if (user) {
      if (!isAuthenticated()) {
        navigate("/logout?wrong-token=true");
      } else {
        setToken(getUser().token.key);
        handleProfile();
      }
    }
    window.addEventListener(
      "beforeinstallprompt",
      (e) => {
        e.preventDefault();
        promptHandler.current = e;
      },
      { once: true }
    );
  }, []);

  const phone = useMediaQuery(`(max-width: ${breakpoints.sm}px)`);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        // sx={{ mt: 2 }}
      >
        <Container fixed sx={{ py: 2 }}>
          <Grid container>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <img src="/logo.png?v=1" width={phone ? "65px" : "100px"} />
              </Box>
              {isAuthenticated() && (
                <Box sx={{ flexGrow: 1 }}>
                  <Button
                    LinkComponent={RouterLink}
                    color="primary"
                    sx={{
                      color: "white",
                    }}
                    to={routeNames.homepage}
                  >
                    Sessions
                  </Button>
                </Box>
              )}
              <UserDropDown user={user} />
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <Box sx={{ flexGrow: 1 }}>
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          }
        >
          <Routes>
            {routes.map(
              ({
                path,
                Main,
                routeName,
                authRequired,
                redirect,
                notAuthenticated,
                render,
              }) => {
                const validated =
                  render === undefined ||
                  (typeof render === "function" && render(usageData));
                if (validated === true)
                  return (
                    <Route
                      key={routeName}
                      path={path}
                      element={
                        authRequired && !isAuthenticated() ? (
                          <Navigate
                            to={
                              routeNames.login +
                              (path !== routeNames.homepage &&
                              path !== routeNames.logout
                                ? "?next=" + path
                                : "")
                            }
                            replace
                          />
                        ) : notAuthenticated && isAuthenticated() ? (
                          <Navigate to={routeNames.homepage} />
                        ) : redirect ? (
                          <Navigate to={redirect} replace />
                        ) : (
                          <Main
                            user={user}
                            {...(path === routeNames.login
                              ? {
                                  onLogin: (payload) => {
                                    setUser(payload);
                                    handleProfile();
                                  },
                                }
                              : path === routeNames.logout
                              ? { onLogout: setUser }
                              : {})}
                          />
                        )
                      }
                    />
                  );
                else return;
              }
            )}
          </Routes>
        </Suspense>
      </Box>
    </Box>
  );
};

export default App;
